export const getRandomInt = (min: number, max: number): number => {
  // Adjust min and max to integer values
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);

  // Validate the input range
  if (minInt >= maxInt) {
    const error =
      "Invalid arguments: 'min' must be less than 'max' after adjusting to integer bounds.";
    throw new Error(error);
  }

  // Generate a random integer between min (inclusive) and max (exclusive)
  return Math.floor(Math.random() * (maxInt - minInt) + minInt);
};

export const decomposeNumber = (input: number): number[] => {
  // Validate the input
  if (!Number.isInteger(input) || input < 0) {
    const error =
      "Invalid argument: input must an integer greater than or equal to zero.";
    throw new Error(error);
  }

  // Generate the decomposed array
  const result: number[] = [];
  const inputStr = input.toString();

  for (let i = 0; i < inputStr.length; i++) {
    const digit = Number(inputStr[i]);
    const placeValue = Math.pow(10, inputStr.length - i - 1);

    for (let j = 0; j < digit; j++) {
      result.push(placeValue);
    }
  }

  return result;
};

export function divideByDecimals(
  number: number | string,
  decimals: number,
): number {
  const num = typeof number === "string" ? parseFloat(number) : number;
  if (isNaN(num)) {
    throw new Error("Invalid number input");
  }
  const divisor = Math.pow(10, decimals);
  return num / divisor;
}

export const getRange = (start: number, end: number, step = 1) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};
